import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Event from "../components/event"

const IndexPage = () => (
  <Layout>
    <SEO title="Kari Pönkkö - Written history" keywords={[`cv`,`gatsby`, `application`, `react`]} />
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                <Event />
            </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
