import React from "react"
import { StaticQuery, graphql } from "gatsby"
import shortid from 'shortid'
import Zoom from 'react-reveal/Zoom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBriefcase, faUserGraduate, faGuitar, faMicrophone, faShieldAlt, faGamepad, faDotCircle, faSitemap, faMotorcycle, faRoad, faAward, faTools, faMusic } from '@fortawesome/free-solid-svg-icons'

const Event = ({ children }) => (
  <StaticQuery
    query={graphql`
      query EventItemsQuery {
        allEventItemsJson {
          edges {
            node {
              date
              title
              description
              icon
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <ul className="timeline timeline-centered">{getEventInfo(data)}</ul>
      </>
    )}
  />
)

function getEventInfo(data) {
  const eventItemsArray = [];
  data.allEventItemsJson.edges.forEach(item => {
    let iconToAdd;
    switch (item.node.icon) {
      case "born":
        iconToAdd = <FontAwesomeIcon icon={faBaby} size="2x" fixedWidth />;
        break;
      case "education":
        iconToAdd = <FontAwesomeIcon icon={faUserGraduate} size="2x" fixedWidth />;
        break;
      case "work":
        iconToAdd = <FontAwesomeIcon icon={faBriefcase} size="2x" fixedWidth />;
        break;
      case "guitar":
        iconToAdd = <FontAwesomeIcon icon={faGuitar} size="2x" fixedWidth />;
        break;
      case "music":
        iconToAdd = <FontAwesomeIcon icon={faMusic} size="2x" fixedWidth />;
        break;
      case "microphone":
        iconToAdd = <FontAwesomeIcon icon={faMicrophone} size="2x" fixedWidth />;
        break;
      case "army":
        iconToAdd = <FontAwesomeIcon icon={faShieldAlt} size="2x" fixedWidth />;
        break;
      case "gaming":
        iconToAdd = <FontAwesomeIcon icon={faGamepad} size="2x" fixedWidth />;
        break;
      case "billiard":
        iconToAdd = <FontAwesomeIcon icon={faDotCircle} size="2x" fixedWidth />;
        break;
      case "sitemap":
        iconToAdd = <FontAwesomeIcon icon={faSitemap} size="2x" fixedWidth />;
        break;
      case "motorcycle":
        iconToAdd = <FontAwesomeIcon icon={faMotorcycle} size="2x" fixedWidth />;
        break;
      case "road":
        iconToAdd = <FontAwesomeIcon icon={faRoad} size="2x" fixedWidth />;
        break;
      case "award":
        iconToAdd = <FontAwesomeIcon icon={faAward} size="2x" fixedWidth />;
        break;
      case "tools":
        iconToAdd = <FontAwesomeIcon icon={faTools} size="2x" fixedWidth />;
        break;
      default:
        iconToAdd = <FontAwesomeIcon icon={faTools} size="2x" fixedWidth />;
    }
    eventItemsArray.push(
      <Zoom key={shortid.generate()}>
        <li className="timeline-item">
          <div className="timeline-info">
            <span>{item.node.date}</span>
          </div>
          <div className="timeline-marker">{iconToAdd}</div>
          <div className="timeline-content">
            <h3 className="timeline-title">{item.node.title}</h3>
            <p>{item.node.description}</p>
          </div>
        </li>
      </Zoom>
    )
  });
  return eventItemsArray;
}

export default Event