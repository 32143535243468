import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header id="example-header" className="example-header">
    <h1 className="text-center">Kari Pönkkö</h1>
    <p>Written history of me</p>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
